import { render, staticRenderFns } from "./posterAtlas.vue?vue&type=template&id=510fa2ba&scoped=true&"
import script from "./posterAtlas.vue?vue&type=script&lang=js&"
export * from "./posterAtlas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510fa2ba",
  null
  
)

export default component.exports